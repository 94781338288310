import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import Sidebar from "../components/sidebar/sidebar"
import HomeScreen from "../components/homescreen/homescreen"
import Sobre from "../components/intro/sobre"
import Lista from "../components/vinhos/sobreTopVinhos"
import Regioes from "../components/regioes/regioes"
import News from "../components/indexNews/indexNews"
import Footer from "../components/footer/footer"
import SidebarMobile from '../components/sidebar/mobile'


const SobreAPPage = ({data}) => {
  const breakpoints = useBreakpoint();
  return(
    <Layout color="#150e04">
      <SEO title="Azul Portugal" />
      {!breakpoints.mobile ?
        <Sidebar switchMenu="left"/>
      :
        <SidebarMobile brown/>
      }
      <HomeScreen data={data.sobreazulJson.homeScreenImg.childImageSharp.fluid} json='azulportugal' azulportugal/>
      <div id='visit'/>
      <Sobre json='azulportugal'/>
      <Lista data={data.sobreazulJson.topVinhos} json='azulportugal' azulPortugal/>
      <Regioes json='azulportugal' azulPortugal/>
      <News data={data.mediaJson.banner.imgbanner.childImageSharp.fluid}/>
      <Footer/>
    </Layout>
  )
}

export default SobreAPPage


export const Json = graphql`
  query sobreazul {
    sobreazulJson {
      homeScreenImg{
        childImageSharp {
          fluid(quality: 100, maxWidth: 4000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      montagem{
        img1{
          childImageSharp {
            fluid(quality: 100, maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        img2{
          childImageSharp {
            fluid(quality: 100, maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      topVinhos{
        list{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          regiao
          ano
        }
      }
      artigos{
        img{
          childImageSharp {
            fluid(quality: 100, maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    mediaJson{
      banner{
        imgbanner{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`